import { type ParsedButton } from "@dotcom/dato";
import {
  Heading,
  PreLinedParagraph,
  type ParsedImageSet,
  Image,
  Button,
} from "@dotcom/ui";
import styled from "@emotion/styled";
import {
  AspectRatio,
  AspectRatios,
  Grid,
  GridColumn,
  MediaQueries,
  Section,
  Spacer,
  Spacings,
} from "@polestar/component-warehouse-react";

interface Props {
  readonly sectionId?: string;
  readonly title: string;
  readonly body: string;
  readonly imageSet: ParsedImageSet;
  readonly button: ParsedButton;
}

const StyledGridColumn = styled(GridColumn)`
  order: -1;

  ${MediaQueries.tablet} {
    order: 0;
  }
`;

const ImageAndTextSection = ({
  sectionId,
  title,
  body,
  button,
  imageSet,
}: Props) => {
  return (
    <Section id={sectionId}>
      <Grid>
        <GridColumn span={{ mobile: 4, tablet: 4, desktop: 3 }}>
          <Heading level={2}>{title}</Heading>

          <Spacer
            spacing={{ mobile: Spacings.small, tablet: Spacings.medium }}
          />

          <PreLinedParagraph>{body}</PreLinedParagraph>

          <Spacer
            spacing={{
              mobile: Spacings.xLarge,
              tablet: Spacings.medium,
              desktop: Spacings.xLarge,
            }}
          />

          <Button
            label={button.label}
            href={button.href}
            variant={button.variant}
            icon={button.icon}
            openInNewTab={button.openInNewTab}
          />
        </GridColumn>

        <StyledGridColumn span={{ mobile: 4, tablet: 4, desktop: 9 }}>
          <AspectRatio
            ratio={{
              mobile: AspectRatios.square,
              desktop: AspectRatios.xWide,
            }}
          >
            <Image
              imageSet={imageSet}
              widths={{
                desktopWide: 900,
                desktop: 800,
                tablet: 400,
                mobile: 400,
              }}
            />
          </AspectRatio>
        </StyledGridColumn>
      </Grid>
    </Section>
  );
};

export default ImageAndTextSection;
