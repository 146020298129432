import { type ParsedButton } from "@dotcom/dato";
import { Button } from "@dotcom/ui";
import styled from "@emotion/styled";
import {
  AspectRatio,
  AspectRatios,
  Image,
  MediaQueries,
  Spacings,
} from "@polestar/component-warehouse-react";

interface CarModelTileProps {
  readonly image: {
    id: string;
    url: string;
    alt?: string;
  };
  readonly button: ParsedButton;
  readonly aspectRatioDesktop: AspectRatios;
}

const StyledContentOverlay = styled.div`
  position: absolute;
  display: flex;
  inset: ${Spacings.small};
  align-items: flex-end;

  ${MediaQueries.tablet} {
    inset: ${Spacings.large};
  }
`;

const CarModelTile = ({
  image,
  button,
  aspectRatioDesktop,
}: CarModelTileProps) => {
  return (
    <AspectRatio
      ratio={{
        mobile: AspectRatios.tall,
        tablet: AspectRatios.square,
        desktop: aspectRatioDesktop,
      }}
    >
      <Image
        stretch
        src={image.url}
        alt={image.alt}
        objectFit="cover"
        objectPosition="50% 50%"
      />

      <StyledContentOverlay>
        <Button
          monochrome
          icon={button.icon}
          variant={button.variant}
          href={button.href}
          openInNewTab={button.openInNewTab}
          label={button.label}
        />
      </StyledContentOverlay>
    </AspectRatio>
  );
};

export default CarModelTile;
