import { type ParsedButton } from "@dotcom/dato";
import styled from "@emotion/styled";
import {
  type AspectRatios,
  Heading,
  MediaQueries,
  Section,
  Spacer,
  Spacings,
  createCustomFontSize,
  createCustomLineHeight,
  createCustomLetterSpacing,
  Grid,
  GridColumn,
} from "@polestar/component-warehouse-react";
import { type Span } from "@polestar/component-warehouse-react/tokens/spans/Spans";

import CarModelTile from "./CarModelTile";
interface CarModelTilesProps {
  readonly sectionId?: string;
  readonly title: string;
  readonly tiles: {
    aspectRatioDesktop: AspectRatios;
    gridSpanDesktop: NonNullable<Span["desktop"]>;
    items: Array<{
      id: string;
      image: {
        id: string;
        url: string;
        alt?: string;
      };
      button: ParsedButton;
    }>;
  };
}

const StyledGrid = styled(Grid)`
  grid-column-gap: ${Spacings.xSmall};
  grid-row-gap: ${Spacings.xSmall};

  ${MediaQueries.tablet} {
    grid-column-gap: ${Spacings.small};
    grid-row-gap: ${Spacings.small};
  }
`;

const CarModelTiles = ({ sectionId, title, tiles }: CarModelTilesProps) => {
  return (
    <Section id={sectionId}>
      <Heading
        variant={{
          fontSize: {
            mobile: createCustomFontSize(3),
            tablet: createCustomFontSize(3.75),
          },
          lineHeight: {
            mobile: createCustomLineHeight(3),
            tablet: createCustomLineHeight(4.25),
          },
          letterSpacing: createCustomLetterSpacing(-0.15),
        }}
        level={1}
      >
        {title}
      </Heading>

      <Spacer spacing={{ mobile: Spacings.large, desktop: Spacings.xLarge }} />

      <StyledGrid>
        {tiles.items.map((tile) => {
          return (
            <GridColumn
              key={tile.id}
              span={{ mobile: 2, tablet: 4, desktop: tiles.gridSpanDesktop }}
            >
              <CarModelTile
                image={tile.image}
                button={tile.button}
                aspectRatioDesktop={tiles.aspectRatioDesktop}
              />
            </GridColumn>
          );
        })}
      </StyledGrid>
    </Section>
  );
};

export default CarModelTiles;
