import { type ParsedButton } from "@dotcom/dato";
import styled from "@emotion/styled";
import {
  ColorVariants,
  Grid,
  Heading,
  LinkGridTile,
  Paragraph,
  Section,
  Spacer,
  Spacings,
} from "@polestar/component-warehouse-react";

interface LinkTilesProps {
  readonly sectionId?: string;
  readonly title: string;
  readonly tiles: Array<
    Readonly<{
      id: string;
      title: string;
      body?: string;
      button: ParsedButton;
    }>
  >;
}

const StyledGrid = styled(Grid)`
  grid-column-gap: ${Spacings.small} !important;
`;

const LinkTiles = ({ sectionId, title, tiles }: LinkTilesProps) => {
  return (
    <Section id={sectionId} data-testid="link-tiles">
      <Heading level={2}>{title}</Heading>

      <Spacer spacing={Spacings.large} />

      <StyledGrid nested>
        {tiles.map((tile) => {
          return (
            <LinkGridTile
              key={tile.id}
              variant={ColorVariants.white}
              span={{
                mobile: 4,
                tablet: 8,
                desktop: tiles.length === 2 ? 6 : 4,
              }}
              href={tile.button?.href}
              linkLabel={tile.button?.label}
              target={tile.button?.openInNewTab ? "_blank" : "_self"}
              rel={
                tile.button?.openInNewTab ? "noopener noreferrer" : undefined
              }
              minHeight={{
                mobile: 200,
                desktop: tiles.length === 2 ? 286 : 245,
              }}
            >
              <Heading level={3}>{tile.title}</Heading>

              <Spacer spacing={Spacings.small} />

              <Paragraph>{tile.body}</Paragraph>
            </LinkGridTile>
          );
        })}
      </StyledGrid>
    </Section>
  );
};

export default LinkTiles;
