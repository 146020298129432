import { type ParsedButton } from "@dotcom/dato";
import { Heading } from "@dotcom/ui";
import styled from "@emotion/styled";
import {
  Colors,
  Grid,
  GridColumn,
  Icon,
  Icons,
  MediaQueries,
  Section,
  Spacer,
  Spacings,
} from "@polestar/component-warehouse-react";

interface Props {
  readonly sectionId?: string;
  readonly title: string;
  readonly buttons: Array<Readonly<ParsedButton>>;
}

const StyledGrid = styled(Grid)`
  grid-template-columns: repeat(4, 1fr) !important;

  ${MediaQueries.tablet} {
    grid-template-columns: repeat(12, 1fr) !important;
  }

  gap: ${Spacings.small} !important;
`;

const StyledGridColumn = styled(GridColumn)`
  height: 100%;
`;

const CustomLinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  padding: ${Spacings.large} ${Spacings.small};
  box-shadow: inset 0 0 0 1px ${Colors.black};
  color: ${Colors.black};

  span {
    padding-right: ${Spacings.xSmall};
    white-space: pre-line;
    word-break: break-word;
  }

  &:active,
  :hover {
    box-shadow: inset 0 0 0 1px rgb(153, 153, 153);
  }

  ${MediaQueries.tablet} {
    padding: ${Spacings.large};
  }
`;

const FaqCategories = ({ sectionId, title, buttons }: Props) => {
  return (
    <Section id={sectionId}>
      <Heading level={2}>{title}</Heading>

      <Spacer spacing={Spacings.large} />

      <StyledGrid>
        {buttons.map((button) => {
          return (
            <StyledGridColumn
              key={button.id}
              span={{ mobile: 2, tablet: 4, desktop: 3 }}
            >
              <CustomLinkButton
                key={button.id}
                href={button.href}
                target={button.openInNewTab ? "_blank" : "_self"}
                rel={button.openInNewTab ? "noopener noreferrer" : undefined}
              >
                <span>{button.label} </span>

                <Icon icon={Icons.arrowRight} color={Colors.safetyOrange} />
              </CustomLinkButton>
            </StyledGridColumn>
          );
        })}
      </StyledGrid>
    </Section>
  );
};

export default FaqCategories;
