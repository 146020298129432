import { useDatadogRum } from "@dotcom/datadog-react";
import { getMarkets, marketHasSupportDialog } from "@dotcom/markets";
import {
  getCanonicalLink,
  getFallbackMeta,
  getFaviconLinks,
} from "@dotcom/remix-meta";
import { Navigation, Footer, NotFoundError, ServerError } from "@dotcom/ui";
import { Header, Spacer, Spacings } from "@polestar/component-warehouse-react";
import { SupportDialog } from "@polestar/support-dialog";
import {
  type HeadersFunction,
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  type SerializeFrom,
} from "@remix-run/node";
import {
  Outlet,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";

import { Document } from "~/common/components";
import { getErrorPageData, getLayoutData } from "~/common/data";
import { getEnablePreview } from "~/common/helpers";
import { getIsChina } from "~/common/helpers/.server";
import { Stage } from "~/common/types";
import styles from "~/styles/shared.css?url";

export type RootLoader = typeof loader;

export const links: LinksFunction = () => {
  return [
    ...getFaviconLinks(),
    {
      rel: "stylesheet",
      href: styles,
    },
  ];
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    "Cache-Control": loaderHeaders.get("Cache-Control") ?? "no-store",
  };
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  return [
    getCanonicalLink(location.pathname, Boolean(data?.isChina)),
    ...getFallbackMeta(data?.layoutData?.fallbackSeo?.fallbackImageUrl),
  ];
};

export const loader = async ({
  context: { market, datoClient },
  request,
}: LoaderFunctionArgs) => {
  const { isPreview } = datoClient;

  if (!market) {
    throw json(
      await getErrorPageData(datoClient, { requestUrl: request.url }),
      { status: 404 }
    );
  }

  const layoutData = await getLayoutData(datoClient, {
    requestUrl: request.url,
    market,
  });

  const isChina = getIsChina();
  // This is not a secret, as it will be exposed to the client anyway. But we handle it in the loader to at least not expose the staging key in production, and vice versa.
  const googleApiKey =
    process.env.STAGE === Stage.Production
      ? "AIzaSyDkSbJK83hj-j3dNHAKq1WZdTgGeXFksbM" // 1Password: "Service Point Locator - Google API Key - Prod"
      : "AIzaSyDu1C4EbAJvu0Q1kVjDz8ueJ7ugvoMmRxs"; // 1Password: "Service Point Locator - Google API Key - Dev"

  return json(
    {
      layoutData,
      market,
      markets: getMarkets(isPreview, isChina),
      isPreview,
      isChina,
      stage: process.env.STAGE as Stage,
      version: process.env.VERSION,
      hasSupportDialog: marketHasSupportDialog(market),
      googleApiKey,
    },
    {
      headers: {
        "Cache-Control": isPreview
          ? "no-store"
          : "maxage=30, s-maxage=60, stale-while-revalidate=86400, stale-if-error=86400",
      },
    }
  );
};

const getPreviewApiEndpoint = (isPreview: boolean, pathname: string) => {
  return `/support/api/preview?preview=${!isPreview}&redirectTo=${pathname}`;
};

const App = () => {
  const {
    stage,
    isPreview,
    isChina,
    market,
    layoutData,
    version,
    hasSupportDialog,
    googleApiKey,
  } = useLoaderData<typeof loader>();
  const { pathname } = useLocation();

  useDatadogRum(
    {
      version: version ?? "no-version",
      clientToken: "pubda28aa0c1acf193467df08090b1a8bef",
      service: "dotcom.support",
      env: stage,
    },
    isChina
  );

  const previewConfig = {
    enablePreview: getEnablePreview(stage),
    isPreview,
    previewApiEndpoint: getPreviewApiEndpoint(isPreview, pathname),
    ephemeralDeploymentId: version,
  };

  return (
    <Document
      stage={stage}
      isChina={isChina}
      languageCode={market.languageCode}
      enableRightToLeft={market.features.includes("enableRTL")}
      googleApiKey={googleApiKey}
    >
      <Navigation
        market={market}
        importerBanner={layoutData.importerBanner}
        megaMenu={
          layoutData.megaMenu
            ? {
                skipToContentId: "main-content",
                actions: layoutData.megaMenu.actions,
                navigation: layoutData.megaMenu.navigation,
                ariaLabels: layoutData.megaMenu.ariaLabels,
                divider:
                  pathname.includes("/support/faq/") ||
                  pathname.includes("car-servicing-network"), // only show the divider on the FAQ and service locator pages
              }
            : undefined
        }
        dictionary={layoutData.dictionary}
        previewConfig={previewConfig}
      />

      <main id="main-content">
        <Outlet />
      </main>

      {market?.cmsLocale && hasSupportDialog && (
        <SupportDialog
          locale={market?.cmsLocale}
          environment={
            stage === Stage.Production ? "production" : "development"
          }
        />
      )}

      <Footer
        market={market}
        pathname={pathname}
        footer={layoutData.footer}
        importerBanner={layoutData.importerBanner}
        doormat={layoutData.doormat}
        newsletterSignup={layoutData.newsletterSignup}
        marketSelector={layoutData.marketSelector}
        previewConfig={previewConfig}
      />
    </Document>
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  const { pathname } = useLocation();

  // This error is completely unexpected as it's not a 4xx/5xx Response thrown from an action/loader
  if (
    !isRouteErrorResponse(error) ||
    !(error.data as Awaited<ReturnType<typeof getErrorPageData>>)
      .globalErrorPage ||
    !(error.data as Awaited<ReturnType<typeof getErrorPageData>>).layoutData
  ) {
    return (
      <Document languageCode="en">
        <Header menuButton={false} />
        <main>
          <ServerError />
        </main>
      </Document>
    );
  }

  // This error is a a 4xx/5xx Response thrown from an action/loader
  const {
    isPreview,
    globalErrorPage,
    layoutData,
    market,
    stage,
    isChina,
    version,
  } = error.data as SerializeFrom<ReturnType<typeof getErrorPageData>>;

  const hasSupportDialog = marketHasSupportDialog(market);

  const previewConfig = {
    enablePreview: getEnablePreview(stage),
    isPreview,
    previewApiEndpoint: getPreviewApiEndpoint(isPreview, pathname),
    ephemeralDeploymentId: version,
  };

  return (
    <Document
      stage={stage}
      isChina={isChina}
      languageCode={market.languageCode}
      enableRightToLeft={market.features.includes("enableRTL")}
    >
      <Navigation
        market={market}
        importerBanner={layoutData.importerBanner}
        megaMenu={
          layoutData.megaMenu
            ? {
                skipToContentId: "main-content",
                actions: layoutData.megaMenu.actions,
                navigation: layoutData.megaMenu.navigation,
                ariaLabels: layoutData.megaMenu.ariaLabels,
                divider: true,
              }
            : undefined
        }
        dictionary={layoutData.dictionary}
        previewConfig={previewConfig}
      />

      {layoutData.megaMenu && (
        <Spacer
          spacing={{ mobile: Spacings.xLarge, tablet: Spacings.xxLarge }}
        />
      )}

      <main>
        {error?.status === 404 ? (
          <NotFoundError
            title={globalErrorPage?.title}
            message={globalErrorPage?.message}
            imageSrc={globalErrorPage?.image?.url}
            imageAlt={globalErrorPage?.image?.alt}
            button={globalErrorPage?.button}
          />
        ) : (
          <ServerError
            title={globalErrorPage?.title}
            message={globalErrorPage?.message}
            label={globalErrorPage?.linkText}
          />
        )}
      </main>

      {market?.cmsLocale && hasSupportDialog && (
        <SupportDialog
          locale={market?.cmsLocale}
          environment={
            stage === Stage.Production ? "production" : "development"
          }
        />
      )}

      <Footer
        market={market}
        pathname={pathname}
        importerBanner={layoutData.importerBanner}
        footer={layoutData.footer}
        doormat={layoutData.doormat}
        newsletterSignup={layoutData.newsletterSignup}
        marketSelector={layoutData.marketSelector}
        previewConfig={previewConfig}
      />
    </Document>
  );
};

export default App;
