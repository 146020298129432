/**
 * Section ID's
 * These section are used to anchor link to a specific section on a page.
 * We decided to hardcode these instead adding new Dato fields.
 */
enum SectionId {
  CAR_MODEL = "car-model",
  FAQ_CATEGORIES = "faq",
  IMAGE_AND_TEXT = "image-and-text",
  HANDOFF = "hand-off",
}

export default SectionId;
