import { getAlternateLinks, getPageMeta, mergeMeta } from "@dotcom/remix-meta";
import { Disclaimer } from "@dotcom/ui";
import {
  BannerBar,
  BannerBarVariants,
  SectionSpacer,
  Spacer,
  Spacings,
} from "@polestar/component-warehouse-react";
import { json, type LoaderFunctionArgs } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { useState } from "react";

import { CarModelTiles } from "./components";
import getSupportOverview from "./data/getSupportOverview";

import { FaqCategories, LinkTiles } from "~/common/components";
import ImageAndTextSection from "~/common/components/ImageAndTextSection";
import { getErrorPageData } from "~/common/data";
import { getIsChina } from "~/common/helpers/.server";
import { type RootLoader } from "~/root";
import SectionId from "./types/SectionId";

export const meta = mergeMeta<typeof loader, { root: RootLoader }>(
  ({ data, location }) => {
    if (!data) return [];

    const {
      publishedMarkets,
      seo: { title, description, image, twitterCard, noIndex },
    } = data.overview.meta;

    return [
      ...getPageMeta({
        title,
        description,
        image: image.url,
        twitterCard,
        noIndex,
      }),
      ...getAlternateLinks(location.pathname, publishedMarkets, data.isChina),
    ];
  }
);

export const loader = async ({
  context: { market, datoClient },
  request,
}: LoaderFunctionArgs) => {
  if (!market) {
    throw json(
      await getErrorPageData(datoClient, {
        requestUrl: request.url,
        market,
      }),
      { status: 404 }
    );
  }

  const overview = await getSupportOverview(datoClient, { market });
  if (!overview) {
    throw json(
      await getErrorPageData(datoClient, {
        requestUrl: request.url,
      }),
      { status: 404 }
    );
  }

  return {
    overview,
    market,
    isChina: getIsChina(),
  };
};

const SupportLandingPage = () => {
  const { overview } = useLoaderData<typeof loader>();

  const [hideNotificationBanner, setHideNotificationBanner] = useState(false);

  return (
    <>
      {!hideNotificationBanner && overview.notificationBanner?.body && (
        <BannerBar
          withCloseButton
          message={overview.notificationBanner.body}
          actionButtonLabel={overview.notificationBanner.button?.label}
          actionButtonProps={{
            href: overview.notificationBanner.button?.href,
            target: overview.notificationBanner.button?.openInNewTab
              ? "_blank"
              : "_self",
            rel: overview.notificationBanner.button?.openInNewTab
              ? "noopener noreferrer"
              : undefined,
          }}
          variant={BannerBarVariants.critical}
          onClose={() => {
            setHideNotificationBanner(true);
          }}
        />
      )}

      <Spacer spacing={Spacings.large} />

      {overview.title && overview.carModelTiles.items.length > 0 && (
        <>
          <CarModelTiles
            sectionId={SectionId.CAR_MODEL}
            title={overview.title}
            tiles={overview.carModelTiles}
          />

          <Spacer spacing={Spacings.xxLarge} />
        </>
      )}

      {overview.faqCategories?.title &&
        overview.faqCategories?.buttons.length > 0 && (
          <>
            <FaqCategories
              sectionId={SectionId.FAQ_CATEGORIES}
              title={overview.faqCategories.title}
              buttons={overview.faqCategories.buttons}
            />

            <SectionSpacer />
          </>
        )}

      {overview.imageAndText?.button && overview.imageAndText.imageSet && (
        <>
          <ImageAndTextSection
            sectionId={SectionId.IMAGE_AND_TEXT}
            title={overview.imageAndText.title}
            body={overview.imageAndText.body}
            button={overview.imageAndText.button}
            imageSet={overview.imageAndText.imageSet}
          />

          <SectionSpacer />
        </>
      )}

      {overview.handoff && (
        <>
          <LinkTiles
            sectionId={SectionId.HANDOFF}
            title={overview.handoff.title}
            tiles={overview.handoff.tiles}
          />

          <SectionSpacer />
        </>
      )}

      {overview.disclaimer && (
        <>
          <Disclaimer text={overview.disclaimer} />

          <SectionSpacer />
        </>
      )}
    </>
  );
};

export default SupportLandingPage;
